import { createRouter, createWebHistory } from "vue-router";
import thestore from "@/applibs/thestore.js";

import Home from "../pages/Home.vue";

import AuthLogin from "../pages/auth/login.vue";
import AuthRegister from "../pages/auth/register.vue";
import AuthEmailVerification from "../pages/auth/email-verification.vue";
import AuthEmailVerificationTokenRequest from "../pages/auth/email-verification-token-request.vue";
import AuthForgotPassword from "../pages/auth/forgot-password.vue";
import AuthPasschangeForm from "../pages/auth/passchange-form.vue";
import AuthTwoFactor from "../pages/auth/two-factor-auth.vue";

// import About from "../pages/About.vue";
// import PageFaq from "../pages/p/Faq.vue";
import PagesTermAndConditions from "../pages/p/TermAndConditions.vue";
import PrivacyPolicy from "../pages/p/PrivacyPolicy.vue";
import ContactUs from "../pages/p/ContactUs.vue";
import Maintenance from "../pages/p/Maintenance.vue";

import SettingsIndex from "../pages/Settings/Index.vue";
import SettingsProfile from "../pages/Settings/Profile.vue";

import SettingsBank from "../pages/Settings/Bank.vue";

import SettingsWallets from "../pages/Settings/Wallets/index.vue";
import SettingsWalletsDeposit from "../pages/Settings/Wallets/deposit.vue";
import SettingsWalletsWithdraw from "../pages/Settings/Wallets/withdraw.vue";

import SettingsWalletsIdrDeposit from "../pages/Settings/Wallets/idr_deposit.vue";
import SettingsWalletsIdrDepositUploadFile from "../pages/Settings/Wallets/idr_deposit_upload_file.vue";
import SettingsWalletsIdrWithdraw from "../pages/Settings/Wallets/idr_withdraw.vue";

import SettingsWalletsBtcDeposit from "../pages/Settings/Wallets/btc_deposit.vue";
import SettingsWalletsBtcWithdraw from "../pages/Settings/Wallets/btc_withdraw.vue";

import SettingsAgreementSign from "../pages/Settings/AgreementSign.vue";
import SettingsAgreementSignTwo from "../pages/Settings/AgreementSignTwo.vue";

// import SettingsBonus from "../pages/Settings/bonus.vue";
import SettingsTwoFa from "../pages/Settings/two-fa.vue";
import SettingsDeleteAccount from "../pages/Settings/DeleteAccount.vue";

import KycNationality from "../pages/kyc/nationality.vue";

import KycIdSatu from "../pages/kyc/id/satu.vue";
import KycIdDua from "../pages/kyc/id/dua.vue";
import KycIdTiga from "../pages/kyc/id/tiga.vue";
import KycIdEmpat from "../pages/kyc/id/empat.vue";

import KycGlobalSatu from "../pages/kyc/global/satu.vue";
import KycGlobalDua from "../pages/kyc/global/dua.vue";
import KycGlobalTiga from "../pages/kyc/global/tiga.vue";
import KycGlobalEmpat from "../pages/kyc/global/empat.vue";

import Markets from "../pages/markets/Index.vue";
import MarketsExchange from "../pages/markets/Exchange.vue";

async function serverMaintenanceChecker() {
  try {
    const res = await thestore.getServerStatus();

    if (res.msg === "Server maintenance") {
      return true;
    }

    return false;
  } catch (error) {
    console.log(error.response.status);
    if (error.response.status == 503) {
      return true;
    } else {
      return false;
    }
  }
}

const router = createRouter({
  history: createWebHistory(),
  mode: "history",
  scrollBehavior(_, _2, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    { path: "/", component: Home, name: "Home" },

    {
      path: "/pages/term-and-conditions",
      component: PagesTermAndConditions,
      name: "Term & Conditions",
    },
    {
      path: "/pages/contact-us",
      component: ContactUs,
      name: "Contact Us",
    },
    {
      path: "/pages/privacy-policy",
      component: PrivacyPolicy,
      name: "Privacy Policy",
    },

    {
      path: "/maintenance",
      component: Maintenance,
      name: "Maintenance",
    },

    { path: "/auth/login", component: AuthLogin, name: "Login" },
    { path: "/auth/register", component: AuthRegister, name: "Register" },
    {
      path: "/auth/email-verification",
      component: AuthEmailVerification,
      name: "Email Verification",
    },
    {
      path: "/auth/email-verification-token-request",
      component: AuthEmailVerificationTokenRequest,
      name: "Verification Token Request",
    },
    {
      path: "/auth/forgot-password",
      component: AuthForgotPassword,
      name: "Forgot Password",
    },
    {
      path: "/auth/passchange-form",
      component: AuthPasschangeForm,
      name: "Change Password",
    },
    {
      path: "/auth/two-factor-auth",
      component: AuthTwoFactor,
      name: "Two Factor Auth",
    },

    { path: "/markets", component: Markets, name: "Market" },
    {
      path: "/markets/:market_id/:market_pair",
      component: MarketsExchange,
      name: "exchange",
    },

    { path: "/settings/index", component: SettingsIndex },
    {
      path: "/settings/profile",
      component: SettingsProfile,
      name: "settings_profile",
    },

    {
      path: "/settings/wallets/btc_deposit",
      component: SettingsWalletsBtcDeposit,
      name: "settings_wallets_btc_deposit",
    },
    {
      path: "/settings/wallets/btc_withdraw",
      component: SettingsWalletsBtcWithdraw,
      name: "settings_wallets_btc_withdraw",
    },

    { path: "/settings/bank", component: SettingsBank, name: "settings_bank" },
    { path: "/settings/2fa", component: SettingsTwoFa, name: "settings_2fa" },
    {
      path: "/settings/delete-account",
      component: SettingsDeleteAccount,
      name: "setting_delete_account",
    },

    // {
    //   path: "/settings/bonus",
    //   component: SettingsBonus,
    //   name: "settings_bonus",
    // },

    {
      path: "/settings/wallets",
      component: SettingsWallets,
      name: "settings_wallets",
    },
    {
      path: "/settings/wallets/:currency/deposit",
      component: SettingsWalletsDeposit,
      name: "settings_wallets_deposit",
    },
    {
      path: "/settings/wallets/:currency/withdraw",
      component: SettingsWalletsWithdraw,
      name: "settings_wallets_withdraw",
    },

    {
      path: "/settings/wallets/idr_deposit",
      component: SettingsWalletsIdrDeposit,
      name: "settings_wallets_idr_deposit",
    },
    {
      path: "/settings/wallets/idr_deposit/upload-file",
      component: SettingsWalletsIdrDepositUploadFile,
      name: "settings_wallets_idr_deposit_upload_file",
    },
    {
      path: "/settings/wallets/idr_withdraw",
      component: SettingsWalletsIdrWithdraw,
      name: "settings_wallets_idr_withdraw",
    },
    {
      path: "/settings/agreement-sign",
      component: SettingsAgreementSign,
      name: "settings_agreement_sign",
    },
    {
      path: "/settings/agreement-sign-two",
      component: SettingsAgreementSignTwo,
      name: "settings_agreement_sign_two",
    },

    // { path: "/about", component: About },
    // { path: "/p/faq", component: PageFaq },

    {
      path: "/kyc/nationality",
      component: KycNationality,
      name: "Nationality",
    },

    { path: "/kyc/id/satu", component: KycIdSatu, name: "Kyc Satu" },
    { path: "/kyc/id/dua", component: KycIdDua, name: "Kyc Dua" },
    { path: "/kyc/id/tiga", component: KycIdTiga, name: "Kyc Tiga" },
    { path: "/kyc/id/empat", component: KycIdEmpat, name: "Kyc Empat" },

    {
      path: "/kyc/global/satu",
      component: KycGlobalSatu,
      name: "Kyc Global Satu",
    },
    {
      path: "/kyc/global/dua",
      component: KycGlobalDua,
      name: "Kyc Global Dua",
    },
    {
      path: "/kyc/global/tiga",
      component: KycGlobalTiga,
      name: "Kyc Global Tiga",
    },
    {
      path: "/kyc/global/empat",
      component: KycGlobalEmpat,
      name: "Kyc Global Empat",
    },
  ],
});

router.beforeEach(async function (to, _2, next) {
  try {
    const isMaintenance = await serverMaintenanceChecker();

    if (!isMaintenance && to.path === "/maintenance") {
      // jika server tidak sedang maintenance tetapi user menuju path maintenance, user akan dilempar ke home

      return next("/");
    } else if (isMaintenance && to.path !== "/maintenance") {
      // jika server sedang maintenance tetapi user menuju semua path kecuali path maintenance, user akan dilempar ke maintenance

      return next("/maintenance");
    }

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);

    const appName = "Bitbromo";
    const metaName = to.name;
    document.title = `${appName} | ${metaName}`;

    next();
  } catch (err) {
    // catch ini berfungsi jika terjadi error pada function serverMaintenanceChecker() maka halaman yang dituju tetap ditampilkan, jika tidak maka halaman akan blank

    next();
  }
});

export default router;
