<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-container">
        <div class="modal-header">
          <slot name="header">default header</slot>
        </div>

        <div class="modal-body">
          <div class="nav_item">
            <ul>
              <li>
                <router-link to="/" class="nav_link">Home</router-link>
              </li>
              <li>
                <router-link to="/markets" class="nav_link">Market</router-link>
              </li>
              <li>
                <router-link :to="themain.defaultMarketPair" class="nav_link"
                  >Exchange</router-link
                >
              </li>

              <li v-if="token" class="nav-item">
                <router-link :to="`/settings/profile`" class="nav_link">
                  Profil
                </router-link>
              </li>
              <li v-if="token" class="nav-item">
                <router-link :to="`/settings/wallets`" class="nav_link">
                  Dompet Saya
                </router-link>
              </li>

              <li
                class="nav-item"
                v-if="user.orang_kyc_status == 'not approve'"
              >
                <div class="nav_kyc">
                  <button @click="showModalKyc = true">Mulai KYC</button>
                </div>
                <!-- <div @click="metamapHandler" class="nav_kyc">
                  <button>Mulai KYC</button>
                  <metamap-button
                    ref="metamapButton"
                    :clientid="metamap.metamapClientId"
                    :flowid="metamap.metamapFlowId"
                    :metadata="metadata"
                    class="metamap_btn"
                    :class="!metamap.metamapReady && 'pointer_events_none'"
                  >
                  </metamap-button>
                </div> -->
              </li>

              <!-- jika status kyc approve tetapi belum tandatangani agreement, bisa munculkan button ini -->
              <li
                class="nav-item"
                v-if="
                  user.orang_kyc_status == 'approve' &&
                  (user.orang_agreement == false ||
                    user.orang_agreement == null)
                "
              >
                <router-link
                  to="/settings/agreement-sign"
                  class="nav_link nav_kyc_update"
                  >KYC Update</router-link
                >
              </li>

              <li v-if="token" class="nav-item">
                <router-link @click="logoutAccount" to="#" class="nav_link red">
                  Keluar
                </router-link>
              </li>

              <div v-if="!token" class="btn_wrapper">
                <router-link to="/auth/login" class="btn_login"
                  >Login</router-link
                >
                <router-link to="/auth/register" class="btn_register"
                  >REGISTER</router-link
                >
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Transition>

  <Teleport to="body">
    <ModalKyc :show="showModalKyc" @close="showModalKyc = false" />
  </Teleport>
</template>

<script>
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import ModalKyc from "./ModalKyc.vue";

export default {
  props: {
    show: Boolean,
    user: Array,
    token: String,
    logoutAccount: Function,
    metadata: String,
  },

  components: { ModalKyc },

  data() {
    return {
      themain,

      metamap: {
        metamapReady: false,
        metamapClientId: null,
        metamapFlowId: null,
      },

      showModalKyc: false,
    };
  },

  methods: {
    async metamapHandler() {
      if (!this.metamap.metamapReady) {
        const res = await thestore.getConfig();

        if (res.metamapClientId && res.metamapFlowId) {
          this.metamap.metamapClientId = res.metamapClientId;
          this.metamap.metamapFlowId = res.metamapFlowId;

          this.$refs.metamapButton.click();
        }

        this.metamap.metamapReady = true;
      }
    },
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;
}

.modal-container {
  width: 300px;
  height: 100%;
  margin: auto;
  padding: 20px 30px;
  background-color: #241E48;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;

  position: absolute;
  right: 0;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  align-items: start;
  /* background: #42b983; */
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.nav_item {
  display: flex;
}
ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}
.nav_link {
  color: white;
}
.nav_link:hover {
  color: #ec2a06;
}
.red {
  color: red;
}
.btn_wrapper {
  display: flex;
  gap: 16px;
  margin-top: 10px;
}
.btn_login {
  padding: 4px 20px;
  border-radius: 8px;
  border: none;
  background: var(
    --button,
    linear-gradient(224deg, #DE3A10 21.62%, #9c280b 93.87%)
  );
  font-size: 14px;
  font-weight: 500;
  color: white;
}
.btn_register {
  padding: 4px 20px;
  border-radius: 8px;
  border: 1px solid white;
  font-size: 14px;
  font-weight: 500;
  color: white;
}

.nav_kyc {
  position: relative;
  cursor: pointer;
}

/* .nav_kyc button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ef7f1f;
  width: 100%;

  background: none;
  border: 1px solid #ef7f1f;
  border-radius: 3px;
} */

.nav_kyc button {
  /* color: #dd2730; */
  color: white;
  /* margin-left: -8px;
  margin-top: -2px; */
  width: 100%;

  background: none;
  border: 1px solid white;
  border-radius: 3px;
}

.nav_kyc_update {
  position: relative;
  cursor: pointer;
  background: none;
  border: 1px solid white;
  border-radius: 3px;
  color: white;
  padding: 2px 4px;
  font-weight: 600;
}

.metamap_btn {
  opacity: 0;
  width: 150px;
}

.pointer_events_none {
  pointer-events: none;
}

@media only screen and (max-width: 450px) {
  .modal-container {
    width: 100%;
  }
}
</style>
