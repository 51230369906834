<!-- 
  halaman ini hanya bisa diakses ketika status kyc approve 
  dan agreement masih false
-->
<template>
  <div class="st_p-wrapper">
    <TopNav />

    <div class="settings mtb15">
      <div class="container-fluid">
        <div v-if="isAllowForAgreement" class="row">
          <Menu />

          <div class="col-md-12 col-lg-9">
            <!-- bagian text agreement -->
            <div class="row px-3 px-lg-0">
              <div
                class="col-12"
                style="background-color: #fff; padding: 15px; max-width: 900px"
              >
                <AgreementText :user="user" />
              </div>
            </div>

            <!-- bagian form agreement -->
            <div class="row mt-4">
              <div
                class="btn btn-gradient mx-auto mx-lg-0"
                @click="btnAgreementClicked"
              >
                Saya menyetujui Surat Perjanjian diatas
              </div>
            </div>
          </div>

          <!-- modal informasi -->
          <div
            class="modal fade text-light"
            tabindex="-1"
            aria-hidden="true"
            v-bind:id="`info-modal`"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header px-4 py-3">
                  <h5 class="modal-title">Informasi</h5>
                </div>

                <div class="modal-body px-4 py-3">
                  <p class="lead mb-4">
                    Selamat! Proses <strong>KYC</strong> Anda telah disetujui.
                  </p>
                  <p class="mb-3">
                    Sesuai dengan
                    <strong
                      >PERATURAN BADAN PENGAWAS PERDAGANGAN BERJANGKA KOMODITI
                      NOMOR 8 TAHUN 2021 TENTANG PEDOMAN PENYELENGGARAAN
                      PERDAGANGAN PASAR FISIK ASET KRIPTO (CRYPTO ASSET) DI
                      BURSA BERJANGKA (Pasal 24),
                    </strong>
                    PT Everest Kripto Indonesia surat perjanjian yang terlampir
                    di halaman ini.
                  </p>
                  <p class="mb-3">
                    Surat Perjanjian Pelanggan Aset kripto ini juga terlampir
                    pada email yang Anda daftarkan.
                  </p>
                  <p class="mb-0">
                    Silakan baca surat perjanjian di bawah ini, dan tekan tombol
                    <strong>"Saya Menyetujui"</strong> jika Anda setuju dengan
                    perjanjian tersebut.
                  </p>
                </div>

                <div class="modal-footer d-flex justify-content-center">
                  <button
                    type="button"
                    class="btn btn-sm btn-gradient px-4 py-2"
                    @click="themain.closeModal('info-modal')"
                  >
                    Tutup
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="isAllowForAgreement === false" class="row">
          <Menu />

          <div class="col-md-12 col-lg-9">
            <div class="card py-3">
              <div class="card-body">
                <h5 class="text-center text-light">
                  Status KYC Anda <strong>Belum Disetujui</strong> atau
                  <strong>Sudah Menyetujui Kesepakatan</strong>
                </h5>
                <ErrorAnimation class="mt-4" />
              </div>
            </div>
          </div>
        </div>

        <div v-else class="row">
          <Menu />

          <div class="col-md-12 col-lg-9">
            <div class="card py-3">
              <div class="card-body text-center">
                <span
                  class="spinner-border mx-auto text-light"
                  aria-hidden="true"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
// import axios from "axios";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import Menu from "@/components/Settings/Menu.vue";
import AgreementText from "@/components/global/AgreementText.vue";
import ErrorAnimation from "@/components/animations/ErrorAnimation.vue";

export default {
  components: {
    TopNav,
    Footer,
    Menu,
    AgreementText,
    ErrorAnimation,
  },
  data() {
    return {
      themain,
      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),
      user: {},
      isAllowForAgreement: undefined,
    };
  },

  async beforeMount() {
    var self = this;
    // cek expire token
    if (self.token) {
      var res = await thestore.postCheckToken(self.token);
      if (res.success == false) {
        Cookies.remove("jwt");
        Cookies.remove("usid");
        self.$router.go();
      }
    }
  },

  async mounted() {
    var self = this;

    // ambil data dia ketika login
    if (self.token != null && self.token != "") {
      var res = await thestore.getMyProfileData(self.token);
      self.user = res;

      // cegah user ini mengakses jika belum di approve kyc
      if (res.orang_kyc_status == "approve" && res.orang_agreement == false) {
        this.isAllowForAgreement = true;
        themain.openModal("info-modal");
      } else {
        this.isAllowForAgreement = false;
      }
    } else {
      self.$router.push("/");
    }
  },

  methods: {
    async btnAgreementClicked() {
      var self = this;
      var res = await thestore.postAgreementUserAgree(self.token);
      self.$router.push("/settings/profile");
    },
  },
};
</script>

<style scoped>
.btn-close {
  background: none;
  border: none;
}

.modal-title {
  font-weight: bold;
}
</style>
