import { createStore } from "vuex";

import axios from "axios";

// applibs
// import themain from "@/applibs/themain.js";

// import Data from "../assets/DummyData/data.json";
export default createStore({
  state() {
    return {
      valueSelected: "0.0001",
      floatDigit: 4,
      // allData: Data,
    };
  },

  mutations: {
    // connectedAddress(state, connectedAddress) {
    //   state.connectedAddress = connectedAddress;
    // },
    bnbUsdtPrice(state, theValue) {
      state.bnbUsdtPrice = theValue;
    },

    setValueSelected(state, val) {
      state.valueSelected = val;
    },
    setFloatDigit(state, digit) {
      state.floatDigit = digit;
    },
  },
  actions: {
    // async getConnectedAddress({ commit }) {
    //   var connectedAddress;
    //   if (window.ethereum || window.ethProvider) {
    //     connectedAddress = await getConnectedAddress();

    //     if (connectedAddress != null) {
    //       // cek di database
    //       await axios({
    //         method: "post",
    //         url: `${themain.baseApi}/users/connect-me`,
    //         data: {
    //           address: connectedAddress,
    //         },
    //       });
    //     }
    //   } else {
    //     connectedAddress = "";
    //   }
    //   // dari sini panggil mutations untuk mengganti datanya
    //   commit("connectedAddress", connectedAddress);
    // },
    async getBnbUsdtPrice({ commit }) {
      var response = await axios({
        url: `https://api.binance.com/api/v3/ticker/price?symbol=BTCUSDT`,
        method: "get",
      });

      commit("bnbUsdtPrice", parseFloat(response.data.price));
    },

    changeDigitFloat({ commit }, val) {
      switch (val) {
        case "0.0001":
          commit("setFloatDigit", 4);
          break;
        case "0.001":
          commit("setFloatDigit", 3);
          break;
        case "0.01":
          commit("setFloatDigit", 2);
          break;
        case "0.1":
          commit("setFloatDigit", 1);
          break;
        case "1":
          commit("setFloatDigit", 0);
          break;
        default:
          break;
      }
      commit("setValueSelected", val);
    },
  },
  getters: {
    sells(state) {
      return state.allData.sells;
    },

    connectWith(state) {
      return state.allData.connectWith;
    },
  },
});
