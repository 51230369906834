<template>
  <div class="h-100">
    <!-- Pesanan kosong -->
    <div v-if="!myobooks.length" class="empty_data_container">
      <svg
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          stroke="#787878"
          stroke-linecap="round"
          stroke-width="2"
          d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
        />
      </svg>
      <p class="mt-1 mb-0 text-light" style="font-weight: 500">
        Tidak ada pesanan tersedia
      </p>
    </div>

    <!-- Tabel -->
    <div v-else-if="myobooks.length">
      <!-- Desktop -->
      <table class="table my-open-order-table d-none d-lg-block">
        <thead>
          <tr>
            <th>#</th>
            <th>Waktu</th>
            <th>Beli/Jual</th>
            <th>Harga</th>
            <th>{{ simbol_a }}</th>
            <th>{{ simbol_b }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in myobooks" :key="item">
            <td>
              {{ item.id }}
            </td>

            <!-- time -->
            <td>
              {{ moment(item.created_at).format("YYYY-MM-DD HH:mm:ss") }}
            </td>

            <!-- Buy/Sell -->
            <td>
              <span v-if="item.market_place == 'Limit Buy'" class="green">
                Beli
              </span>
              <span v-if="item.market_place == 'Limit Sell'" class="red">
                Jual
              </span>
            </td>

            <!-- Price -->
            <td>
              <span v-if="item.market_place == 'Limit Buy'" class="green">
                {{
                  parseFloat(item.market_position).numberFormat(
                    currencyBObj.decimal_display
                  )
                }}
              </span>
              <span v-if="item.market_place == 'Limit Sell'" class="red">
                {{
                  parseFloat(item.market_position).numberFormat(
                    currencyBObj.decimal_display
                  )
                }}
              </span>
            </td>

            <!-- simbol_a -->
            <td>
              <div v-if="item.market_place == 'Limit Buy'">
                {{
                  parseFloat(item.market_result_including_fee).numberFormat(
                    currencyAObj.decimal_display
                  )
                }}
              </div>
              <div v-else>
                {{
                  parseFloat(item.market_amount).numberFormat(
                    currencyAObj.decimal_display
                  )
                }}
              </div>
            </td>

            <!-- simbol_b -->
            <td>
              <div v-if="item.market_place == 'Limit Buy'">
                {{
                  parseFloat(item.market_amount).numberFormat(
                    currencyBObj.decimal_display
                  )
                }}
              </div>
              <div v-else>
                {{
                  parseFloat(item.market_result_including_fee).numberFormat(
                    currencyBObj.decimal_display
                  )
                }}
              </div>
            </td>

            <td>
              <button
                class="btn btn-outline-info btn-sm"
                @click="themain.openModal(`myorders-${item.id}-modal`)"
              >
                Batal
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Mobile -->
      <table class="table my-open-order-table-vertical d-block d-lg-none">
        <div
          v-for="item in myobooks"
          :key="item"
          class="m-3 border border-secondary text-light"
        >
          <div class="id_heading px-3 py-2"># {{ item.id }}</div>
          <div class="p-3">
            <!-- Time -->
            <div class="row row-cols">
              <div class="col-4 d-flex justify-content-between">
                <span>Waktu</span> <span>:</span>
              </div>
              <div class="col">
                {{ moment(item.created_at).format("YYYY-MM-DD HH:mm:ss") }}
              </div>
            </div>

            <!-- Buy/Sell -->
            <div class="row row-cols">
              <div class="col-4 d-flex justify-content-between">
                <span>Beli/Jual</span> <span>:</span>
              </div>
              <div class="col">
                <span v-if="item.market_place == 'Limit Buy'" class="green">
                  Beli
                </span>
                <span v-if="item.market_place == 'Limit Sell'" class="red">
                  Jual
                </span>
              </div>
            </div>

            <!-- Price -->
            <div class="row row-cols">
              <div class="col-4 d-flex justify-content-between">
                <span>Harga</span> <span>:</span>
              </div>
              <div class="col">
                <span v-if="item.market_place == 'Limit Buy'" class="green">
                  {{
                    parseFloat(item.market_position).numberFormat(
                      currencyBObj.decimal_display
                    )
                  }}
                </span>
                <span v-if="item.market_place == 'Limit Sell'" class="red">
                  {{
                    parseFloat(item.market_position).numberFormat(
                      currencyBObj.decimal_display
                    )
                  }}
                </span>
              </div>
            </div>

            <!-- simbol_a -->
            <div class="row row-cols">
              <div class="col-4 d-flex justify-content-between">
                <span>{{ simbol_a }}</span> <span>:</span>
              </div>
              <div class="col">
                <div v-if="item.market_place == 'Limit Buy'">
                  {{
                    parseFloat(item.market_result_including_fee).numberFormat(
                      currencyAObj.decimal_display
                    )
                  }}
                </div>
                <div v-else>
                  {{
                    parseFloat(item.market_amount).numberFormat(
                      currencyAObj.decimal_display
                    )
                  }}
                </div>
              </div>
            </div>

            <!-- simbol_b -->
            <div class="row row-cols">
              <div class="col-4 d-flex justify-content-between">
                <span>{{ simbol_b }}</span> <span>:</span>
              </div>
              <div class="col">
                <div v-if="item.market_place == 'Limit Buy'">
                  {{
                    parseFloat(item.market_amount).numberFormat(
                      currencyBObj.decimal_display
                    )
                  }}
                </div>
                <div v-else>
                  {{
                    parseFloat(item.market_result_including_fee).numberFormat(
                      currencyBObj.decimal_display
                    )
                  }}
                </div>
              </div>
            </div>
          </div>
          <div>
            <button
              class="btn btn-outline-info btn-sm w-100"
              @click="themain.openModal(`myorders-${item.id}-modal`)"
            >
              Batal
            </button>
          </div>
        </div>
      </table>
    </div>

    <!-- Modal -->
    <div
      v-for="item in myobooks"
      :key="item.id"
      class="modal fade text-light"
      tabindex="-1"
      aria-hidden="true"
      v-bind:id="`myorders-${item.id}-modal`"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Konfirmasi</h5>
            <button
              type="button"
              class="btn btn-danger rounded-circle p-1"
              aria-label="Close"
              @click="themain.closeModal(`myorders-${item.id}-modal`)"
              :disabled="modalCloseBtnDisabled"
            >
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18 17.94 6M18 18 6.06 6"
                />
              </svg>
            </button>
          </div>
          <div class="modal-body py-5 text-center">
            <strong style="font-size: medium">Apakah Anda yakin ?</strong>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="themain.closeModal(`myorders-${item.id}-modal`)"
              :disabled="modalCloseBtnDisabled"
            >
              Tidak
            </button>
            <button
              type="button"
              class="btn btn-gradient"
              @click="cancelBuyOrSell(item)"
            >
              Ya
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";
import moment from "moment";

// applibs
import themain from "@/applibs/themain.js";
import thestore from "@/applibs/thestore.js";

// components

export default {
  components: {},
  props: {
    marketData: {
      type: Object,
      required: true,
    },
    currencyAObj: {
      type: Object,
    },
    currencyBObj: {
      type: Object,
    },
  },
  data() {
    return {
      themain,
      moment,

      market_id: this.$route.params.market_id,
      market_pair: this.$route.params.market_pair,

      token: Cookies.get("jwt"),
      usid: Cookies.get("usid"),

      simbol_a: "",
      simbol_b: "",

      myobooks: [],

      modalCloseBtnDisabled: false,
    };
  },

  async created() {
    var self = this;

    // disini kita dengarkan fe-sells dan kita filter jika ada yang mengarah ke kita
    self.emitter.on("fe-sells", async function (e) {
      // console.log("e fe-sells", e);

      // e.data dalam bentuk array orderbook sells
      // Jadi ketikan kita update atau add data, kita dari sini sebaiknya panggil satu per-satu
      // disini kita harus iterasi terlebih dahulu
      e.data.forEach(function (elem) {
        // jika mengarah ke kita, kita harus olah
        if (parseInt(self.usid) == parseInt(elem.orang_id)) {
          self.addOrUpdateMyorders(elem);
        }
      });
    });

    // disini kita dengarkan fe-buys dan kita filter jika ada yang mengarah ke kita
    self.emitter.on("fe-buys", async function (e) {
      // console.log("e fe-buys", e);

      // e.data dalam bentuk array orderbook sells
      // Jadi ketikan kita update atau add data, kita dari sini sebaiknya panggil satu per-satu
      // disini kita harus iterasi terlebih dahulu
      e.data.forEach(function (elem) {
        // jika mengarah ke kita, kita harus olah
        if (parseInt(self.usid) == parseInt(elem.orang_id)) {
          self.addOrUpdateMyorders(elem);
        }
      });
    });

    self.emitter.on("fe-myobook-close", async function (e) {
      var nubooks = [];
      self.myobooks.forEach(function (mbook) {
        if (mbook.id != e.data.id) {
          nubooks.push(mbook);
        }
      });
      self.myobooks = nubooks;
    });
  },

  async mounted() {
    var self = this;

    // simbols
    var simbols = self.marketData.pair.split("_");
    self.simbol_a = simbols[0];
    self.simbol_b = simbols[1];

    // jika ada token, mita ambil myorderbook
    if (self.token != null && self.token != "") {
      await self.getMyOrders();
    }
  },

  methods: {
    async getMyOrders() {
      var self = this;
      var filter_rules = [
        {
          field: "market_pair",
          op: "equal-for-pair",
          value: self.marketData.pair,
        },
      ];
      var myobooksRes = await thestore.getMyOrderBooksOpen(
        self.token,
        1,
        20,
        "updated_at",
        "desc",
        filter_rules
      );

      self.myobooks = myobooksRes.datas;
    },

    /**
     * Memasukkan data yang baru atau update yang datang dari emit frontend
     * @param {*} newDataObj
     */
    addOrUpdateMyorders(newDataObj) {
      var self = this;

      var my_orders_new = [];

      // mengganti data yang baru masuk
      var apa_ada = 0;
      self.myobooks.forEach((ele) => {
        if (ele.id == newDataObj.id) {
          my_orders_new.push(newDataObj);
          apa_ada = apa_ada + 1;
        } else {
          my_orders_new.push(ele);
        }
      });

      // jika tidak ada data yang di update, maka dimasukkan saja data baru
      if (apa_ada == 0) {
        my_orders_new.push(newDataObj);
      }

      // ganti myobooks
      self.myobooks = my_orders_new;
    },

    async cancelBuyOrSell(myorderObj) {
      var self = this;

      var res = await thestore.postCancelOrder(self.token, myorderObj.id);
      themain.closeModal(`myorders-${myorderObj.id}-modal`);
      if (res.success == true) {
        var olds = self.myobooks;
        var news = [];

        // remove data
        olds.forEach(function (el) {
          if (el.id != myorderObj.id) {
            news.push(el);
          }
        });
        self.myobooks = news;
      } else {
        alert(self.msg);
      }
    },
  },
};
</script>

<style scoped>
.my-open-order-table tbody tr,
.my-open-order-table thead tr {
  display: table;
  table-layout: fixed;
  width: 100%;
}

#dark .my-open-order-table thead tr th {
  font-weight: bold;
  color: white;
}

.my-open-order-table thead th,
.my-open-order-table tbody td {
  padding: 5px;
  text-align: center;
}

.my-open-order-table-vertical .id_heading {
  font-weight: bold;
  background-color: #1c2030;
}

.empty_data_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 3rem;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .my-open-order-table-vertical {
    margin-bottom: 6rem;
  }

  .empty_data_container {
    padding-bottom: 77px;
  }
}
</style>
